import React from 'react';
import './ServiceComponent.css'
import Stack from '@mui/material/Stack';

const ServiceComponent = ({ imgSrc , altText , firstText , secondText }) => {
    return(
        <div className='service-component-div'>
            <Stack>
                <img src={imgSrc} alt={altText} className='service-component-logo' />
                <p className='first-text-service-component'>{firstText}</p>
                <p className='second-text-service-component'>{secondText}</p>
            </Stack>
        </div>
    )
}

export default ServiceComponent;