import React from 'react';
import './contact.css'
import ContactPageDecor from '../../assets/contact-page-decor.svg'

const Contact = () => {

    return (
        <div id='contact-page' className='contact-page' >
            <div className='contact-page-para'>
                <img src={ContactPageDecor} alt="Logo with title" className='contact-page-decor' />
                <p className='first-text-contact'>Join <span className='gradient-text'>forces for collaborative excellence and create something extraordinary</span> together!</p>
                <p className='second-text-contact'>Embark on a journey of collaborative excellence as we unite our strengths to create something truly extraordinary.
                    Together, let's innovate, inspire, and build something exceptional that leaves a lasting impact.
                    Join forces with us for a shared vision of greatness! </p>
            </div>
            <button className='explore-more-button-contact'
                onClick={() => window.location = "https://forms.gle/ZhL4HVnEdTf2gniBA"}>Book A Free Trial</button>
        </div>
    )
}

export default Contact;