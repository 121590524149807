import './blogList.css'
import React, { useEffect, useState } from 'react';
import Header from '../../components/header/header'
import Footer from '../../components/footer/footer.js'
import BlogListComponent from '../../components/blogListComponent/blogListComponent.js'
import headingImg from '../../assets/blog-list-page-heading.svg'
import { useNavigate } from 'react-router-dom';
import { BLOGS } from '../../common/Blogs.js'
import { XlviLoaderComponent } from '../../components/Loaders/xlviLoader.js';

const BlogListTopComponent = () => {
    return (
        <div>
            <img src={headingImg} className='blog-list-heading-img' alt="Logo with title"/>
        </div>
    )
}

const BlogListCombinedComponent = ({ blogList }) => {

    const navigate = useNavigate();

    const handleOnClick = (name) => {
        navigate(`/blog/${name}`)
    }

    return (
        <div className='blog-list-combined-component'>
            {blogList.map((blog, index) => (
                <BlogListComponent key={index} blogTitle={blog.title} blogTitleImg={blog.mainImgUrl}
                    blogSubtitle={blog.subtitle} blogTime={blog.readTime} onClick={() => handleOnClick(blog.name)} />
            ))}
        </div>
    )
}

const BlogListComingSoon = () => {
    return (
        <div className='blog-list-coming-soon'>
            <h1 className='text'>Coming Soon ....</h1>
        </div>
    )
}

const BlogList = () => {
    const blogList = BLOGS

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            setLoading(false);
        }, 4000);

        return () => clearTimeout(timer);
    }, []);

    if (loading) {
        return (
            <div>
                <XlviLoaderComponent />
            </div>
        );
    }

    if (blogList.length > 0) {
        return (
            <div className='blog-list-page'>
                <Header />
                <div className='blog-list-top-and-grid'>
                    <BlogListTopComponent />
                    <BlogListCombinedComponent blogList={blogList} />
                </div>
                <Footer />
            </div>
        )
    }
    else {
        return (
            <div className='blog-list-page'>
                <Header />
                <BlogListComingSoon />
                <Footer />
            </div>
        )
    }


}

export default BlogList