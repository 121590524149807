import React from 'react';
import './about.css'
import AboutPageImage from '../../assets/about-page-img.svg'
import AboutPageDecor from '../../assets/about-page-decor.svg'

const About = () => {
    return (
        <div id='about-page' className='about-page' >
            <div className='about-page-para'>
                <img src={AboutPageDecor} alt="Logo with title" className='about-page-decor' />
                <p className='first-text-about'>We are an <span className='gradient-text'>innovative and skilled team of designers and developers</span> based in India.</p>
                <p className='second-text-about'>At TryOuts, we take pride in our creative and talented team of designers and developers located in India.
                    Our passion for innovation drives us to deliver exceptional solutions that cater to the unique needs of our clients.
                    From cutting-edge design concepts to robust development strategies, we work collaboratively to bring visions to life.
                    Explore the synergy of creativity and technical expertise with our dynamic team at TryOuts.</p>
            </div>
            <img src={AboutPageImage} alt="Logo with title" className='about-page-image' />
        </div>
    )
}

export default About;