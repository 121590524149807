import React from 'react';
import './ServicesOffered.css'
import ServicePageDecor from '../../assets/service-page-decor.svg'
import ServiceComponent from '../../components/serviceComponent/ServiceComponent.js';
import { SERVICES_OFFERED } from '../../common/Constants.js';


const Service = () => {
    return (
        <div className='service-page' id='service-page' >
            <div className='service-page-para'>
                <img className='service-page-decor' src={ServicePageDecor} alt='service Page Decor' />
                <p className='first-text-service'>We offer a wide range of digital product services.</p>
                <p className='second-text-service'>In our team we pride ourselves on offering a diverse range of digital product services to meet the needs of our clients. From graphic design to web design, app development to web development and many more. We are equipped to handle a variety of digital products.</p>
            </div>
            <div className='service-page-components'>
                {SERVICES_OFFERED.map((service, index) => (
                    <ServiceComponent key={index}
                        imgSrc={service.imgSrc}
                        altText={service.altText}
                        firstText={service.firstText}
                        secondText={service.secondText} />
                ))}
            </div>
        </div>
    )
}

export default Service;