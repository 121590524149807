// export const BASE_URL = "https://lead-gen-backend.onrender.com/api/v1"
export const BASE_URL = "http://localhost:3333/api/v1"
export const SERVICES_OFFERED = [
    {
        imgSrc : "./graphics-icon.svg",
        altText : "Graphics Icon",
        firstText : "Graphic Design",
        secondText : "Graphic design is defined as the art and practice of planning and projecting ideas and experiences with visual and textual content."
    },
    {
        imgSrc : "/ui-icon.svg",
        altText : "UI Icon",
        firstText : "UI/UX Design",
        secondText : "UX design refers to the term “user experience design” while UI stands for “user interface design”."
    },
    {
        imgSrc : "/app-icon.svg",
        altText : "App Icon",
        firstText : "App Development",
        secondText : "App development is the process by which a mobile app is developed for one or more mobile devices, which can include personal digital assistants, etc."
    },
    {
        imgSrc : "/web-icon.svg",
        altText : "Web Icon",
        firstText : "Web Development",
        secondText : "Web development is the work involved in developing a website for the Internet or an intranet."
    }
]