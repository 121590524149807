import './blog.css'
import { useEffect, useState} from 'react';
import Header from '../../components/header/header'
import Footer from '../../components/footer/footer.js'
import { Stack } from '@mui/material'
import BlogChapter from '../../components/blogChapterComponent/blogChapter.js'
import { useParams } from 'react-router-dom';
import { BLOGS } from '../../common/Blogs.js';
import { XlviLoaderComponent } from '../../components/Loaders/xlviLoader.js';
import { TableOfContentComponent } from '../../components/tableOfContentComponent/tableOfContentComponent.js';

const BlogTitleComponent = ({ blogTitle, blogDate, blogAuthor, blogTitleImg, blogSubtitle }) => {
    return (
        <div>
            <p className='blog-title'>{blogTitle}</p>
            <Stack direction='horizontal' className='blog-info'>
                <p className='blog-author'> Author - {blogAuthor}</p>
                <p className='blog-date'> Date - {blogDate}</p>
            </Stack>
            <img src={blogTitleImg} className='blog-title-img' alt="Logo with title"></img>
            <p className='blog-subtitle'>{blogSubtitle}</p>
        </div>
    )
}

const BlogChapters = ({ blogChapters }) => {
    return (
        <div>
            {blogChapters.map((blogChapter, index) => (
                <BlogChapter key={index} blogChapterTitle={blogChapter.title} id = {blogChapter.id}
                    blogChapterImg={blogChapter.imgUrl} blogChapterContent={blogChapter.content} />
            ))}
        </div>
    )
}

const BlogComponent = ({blog}) => {
    return(
        <div className='blog-title-and-chapters'>
            <BlogTitleComponent
                blogTitle={blog ? blog.title : ""}
                blogDate={blog ? blog.date : ""}
                blogAuthor={blog ? blog.author : ""}
                blogTitleImg={blog ? blog.mainImgUrl : ""}
                blogSubtitle={blog ? blog.subtitle : ""} />
            <BlogChapters blogChapters={blog ? blog.blogChapters : []} />
        </div>
    )
}

const TableOfContents = ({blogChapters}) => {
    return(
        <div className='table-of-contents'>
            <p className='table-of-contents-title'>Table Of Contents</p>
            {blogChapters.map((blogChapter, index) => (
                <TableOfContentComponent key={index} chapterTitle={blogChapter.title} id = {blogChapter.id} />
            ))}
        </div>
    )
}

const Blog = () => {

    const { name } = useParams()
    const [blog, setBlog] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            setLoading(false);
        }, 4000);

        for ( const singleBlog of BLOGS ){
            if ( singleBlog.name === name )
            {
                setBlog(singleBlog)
                console.log(singleBlog)
                break
            }
        }

        return () => clearTimeout(timer);
    }, [name]);

    if (loading) {
        return (
            <div>
                <XlviLoaderComponent />
            </div>
        );
    }

    return (
        <div className='blog-page'>
            <Header />
            <div className='blog-and-table-of-contents'>
                <BlogComponent blog={blog}/>
                <TableOfContents blogChapters={blog ? blog.blogChapters : []}/>
            </div>
            <Footer />
        </div>
    )

}

export default Blog